import { imageAdapter } from '@/adapters/constructor/utils/image';

export default ({ data }) => {
  return {
    title: data.title,
    disclaimer: data.disclaimer,
    text1: data.text1,
    text2: data.text2,
    banner: imageAdapter(data.banner, data.banner_mobile),
  };
};
